import React from "react";
import { TrackedLink } from "../..";

import {
    PartieLogo,
    InstagramLogo,
    TwitterLogo,
    TwitchLogo,
    YoutubeLogo,
} from "../../../svgs/social";

import "./leaders-card.scss";

const LeadersCard = ({ data }) => {
    const {
        leaderName,
        partieProfile,
        twitter,
        instagram,
        youtube,
        twitch
    } = data;

    const partieProfileLink = "https://app.partie.com/profile/" + partieProfile;
    const twitterProfileLink = "https://twitter.com/" + twitter;
    const instagramProfileLink = "https://www.instagram.com/" + instagram;
    const youtubeProfileLink = "https://www.youtube.com/" + youtube;
    const twitchProfileLink = "https://www.twitch.tv/" + twitch;

    return (
        <div className="leaders-card">
            {leaderName && (
                <div className="leaders-card__title">
                    {leaderName}
                </div>
            )}
            <div className="leaders-card__social-links">
                <ul className="leaders-card__social-links-list">
                    {partieProfile && (
                        <li>
                            <TrackedLink rel="noreferrer" target="_blank" label="Partie Account" to={partieProfileLink} children={<PartieLogo />} />
                        </li>
                    )}
                    {twitter && (
                        <li>
                            <TrackedLink rel="noreferrer" target="_blank" label="Twitter Account" to={twitterProfileLink} children={<TwitterLogo />} />
                        </li>
                    )}
                    {instagram && (
                        <li>
                            <TrackedLink rel="noreferrer" target="_blank" label="Instagram Account" to={instagramProfileLink} children={<InstagramLogo />} />
                        </li>
                    )}
                    {youtube && (
                        <li>
                            <TrackedLink rel="noreferrer" target="_blank" label="Youtube Account" to={youtubeProfileLink} children={<YoutubeLogo />} />
                        </li>
                    )}
                    {twitch && (
                        <li>
                            <TrackedLink rel="noreferrer" target="_blank" label="Twitch Account" to={twitchProfileLink} children={<TwitchLogo />} />
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default LeadersCard;
