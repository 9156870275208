export const LEADER_LIST = {
    leaders: [
      {
        leaderName: "Almond",
        partieProfile: "Almond",
        twitter: "Almxnd_",
        instagram: "",
        youtube: "",
        twitch: "Almxnd"
      },
      {
        leaderName: "Bartonologist",
        partieProfile: "Bartonologist",
        twitter: "Bartonologist",
        instagram: "",
        youtube: "",
        twitch: "Bartonologist"
      },
      {
        leaderName: "Destroy",
        partieProfile: "Destroy",
        twitter: "",
        instagram: "",
        youtube: "",
        twitch: "Destroy"
      },
      {
        leaderName: "Holly",
        partieProfile: "Holly",
        twitter: "HollyyLive",
        instagram: "",
        youtube: "",
        twitch: "Holly"
      },
      {
        leaderName: "HusKerrs",
        partieProfile: "HusKerrs",
        twitter: "HusKerrs",
        instagram: "",
        youtube: "",
        twitch: "HusKerrs"
      },
      {
        leaderName: "IcemanIsaac",
        partieProfile: "IcemanIsaac",
        twitter: "IcemanIsaac",
        instagram: "",
        youtube: "",
        twitch: "IcemanIsaac"
      },
      {
        leaderName: "JaredFPS",
        partieProfile: "JaredFPS",
        twitter: "JaredFPS",
        instagram: "",
        youtube: "",
        twitch: "JaredFPS"
      },
      {
        leaderName: "JessieCooks",
        partieProfile: "JessieCooks",
        twitter: "",
        instagram: "",
        youtube: "",
        twitch: "JessieCooks"
      },
      {
        leaderName: "Jukeyz",
        partieProfile: "Jukeyz",
        twitter: "Jukeyz",
        instagram: "",
        youtube: "",
        twitch: "Jukeyz"
      },
      {
        leaderName: "p90princess",
        partieProfile: "p90princess",
        twitter: "p90princess",
        instagram: "",
        youtube: "",
        twitch: "p90princess"
      },
      {
        leaderName: "PicNickBasket",
        partieProfile: "PicNickBasket",
        twitter: "PicNickBasket_",
        instagram: "",
        youtube: "",
        twitch: "PicNickBasket"
      },
      {
        leaderName: "Scummn",
        partieProfile: "Scummn",
        twitter: "Scummn",
        instagram: "",
        youtube: "",
        twitch: "Scummn"
      },
      {
        leaderName: "TheDanDangler",
        partieProfile: "TheDanDangler",
        twitter: "TheDanDanglerFN",
        instagram: "",
        youtube: "",
        twitch: "TheDanDangler"
      },
      {
        leaderName: "Tommey",
        partieProfile: "Tommey",
        twitter: "Tommey",
        instagram: "",
        youtube: "",
        twitch: "Tommey"
      },
      {
        leaderName: "Wagnificent",
        partieProfile: "Wagnificent",
        twitter: "Wagnificentt",
        instagram: "",
        youtube: "",
        twitch: "Wagnificent"
      },
      {
        leaderName: "zColorss",
        partieProfile: "zColorss",
        twitter: "zColorss",
        instagram: "",
        youtube: "",
        twitch: "zColorss"
      },
    ]
  };