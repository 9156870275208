import React, { useState } from "react";

import LeadersCard from "./leaders-card/leaders-card";
import { LEADER_LIST } from "./leader-list";

import "./featured-leaders.scss";

let started = 0;
let totalToBeDisplayed = 12;

const FeaturedLeaders = () => {
    const [canShowMore, setCanShowMore] = useState(false);

    const checkCount = () => {
        started = 1;
        if (LEADER_LIST.leaders.length > totalToBeDisplayed){
            setCanShowMore(true);
        }
    }

    const showMoreClickHandler = () => {
        totalToBeDisplayed = totalToBeDisplayed +12;
        if (totalToBeDisplayed >= LEADER_LIST.leaders.length) {
            setCanShowMore(false)
        }
    };

    return (
        <div className="leaders-grid">
            <h2 className="leaders-grid__title">Team Captains</h2>
            <div className="leaders-grid__grid-container">
                {started == 0 && checkCount()}
                {
                    <>
                        {LEADER_LIST.leaders.slice(0, totalToBeDisplayed).map((leader, index) => (
                            <div key={index} className="leaders-grid__col">
                                <LeadersCard data={leader} />
                            </div>
                        ))}
                    </>
                }
            </div>
            {canShowMore && (
                            <button
                                className="btn leaders-grid__show-more-btn"
                                onClick={showMoreClickHandler}
                            >
                                Show more
                            </button>
                        )}
        </div>
    );
};

export default FeaturedLeaders;